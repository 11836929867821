@import '../core/rwx-variables';
@import '../core/rwx-mixins';
$rwx-animated-border-from-color: $blue !default;
$rwx-animated-border-to-color: $orange !default;
$rwx-animated-border-size: 5px !default;

@mixin finish-styles {
	stroke-dasharray: 50 0;
	stroke-width: $rwx-animated-border-size;
	stroke-dashoffset: 0;
	stroke: $rwx-animated-border-to-color;	
}
.rwx-animated-border
{
	svg
	{
		rect
		{
			stroke-width:$rwx-animated-border-size;
			fill:transparent;
			stroke: $rwx-animated-border-from-color;
			transition: 1s all ease;
		}
		@include onAccessible(true, 'active')
		{
			rect
			{
				@include finish-styles;
			}	
		}
	}
	@include onAccessible(true, 'active')
	{
		outline:none;
		border: none;
		svg rect {
			@include finish-styles;		
		}
	}
}