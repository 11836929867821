@import '../core/rwx-variables';
@import '../core/rwx-mixins';

.rwx-phototile
{
	@include flex-center;
	flex-wrap:wrap;
	canvas
	{
		margin-bottom:20px;
	}
	&-container
	{
		width:100%;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		* {
			cursor:pointer;
			width: 40px;
			height:40px;
			margin-top: $small-spacing;
			position:relative;
			margin-left: $extra-small-spacing;
			margin-right: $extra-small-spacing;
			@include onAccessible(true)
			{
				@include focus-ring;
			}
		}
	}
	&:not(.loaded)
	{
		@extend .rwx-loading !optional;
		img
		{
			visibility: hidden;
		}
	}
}