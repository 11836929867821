body {
	font-family: $font-base;
  font-size: rem($font-size-base);
  line-height: rem($line-height-base);
  color: $font-color-base;
}

@mixin additionalheadingstyle {
  margin-bottom: 1.3rem;
  &.underline
  {
    @extend .rwxt-underline;
  }
  &.no-underline
  {
    @extend .rwxt-no-underline;
  }
  @if $underline-headings
  {
    @extend .rwxt-underline;
  }
}

@mixin headingstyle {
  font-family: $font-heading;
  @extend .rwxt-bold;  
}

$headingincrement: 8px;

@mixin h1 {
  @include headingstyle;
  font-size: rem($h1-size);
  line-height: rem($h1-size) + rem($headingincrement);
  @if $responsiveheadings
  {
    @include snap-down-from('md')
    {
      font-size: rem($h1-size) * 0.01 * $responsiveheadings;
      line-height: rem($h1-size) + rem($headingincrement) * 0.01 * $responsiveheadings;
    }
  }
}

@mixin h2 {
  @include headingstyle;
  font-size: rem($h2-size);
  line-height: rem($h2-size) + rem($headingincrement);
  @if $responsiveheadings
  {
    @include snap-down-from('md')
    {
      font-size: rem($h2-size * 0.01 * $responsiveheadings);
      line-height: rem($h2-size) + rem($headingincrement) * 0.01 * $responsiveheadings;
    }
  }
}

@mixin h3 {
  @include headingstyle;
  font-size: rem($h3-size);
  line-height: rem($h3-size) + rem($headingincrement);
  @if $responsiveheadings
    {
    @include snap-down-from('md')
    {
      font-size: rem($h3-size * 0.01 * $responsiveheadings);
      line-height: rem($h3-size) + rem($headingincrement) * 0.01 * $responsiveheadings;
    }
  }
}

@mixin h4 {
  @include headingstyle;
  font-size: rem($h4-size);
  line-height: rem($h4-size) + rem($headingincrement);
  @if $responsiveheadings
  {
    @include snap-down-from('md')
    {
      font-size: rem($h4-size * 0.01 * $responsiveheadings);
      line-height: rem($h4-size) + rem($headingincrement) * 0.01 * $responsiveheadings;
    }
  }
}

@mixin h5 {
  @include headingstyle;
  font-size: rem($h5-size);
  line-height: rem($h5-size) + rem($headingincrement);
  @if $responsiveheadings
  {
    @include snap-down-from('md')
    {
      font-size: rem($h5-size * 0.01 * $responsiveheadings);
      line-height: rem($h5-size) + rem($headingincrement) * 0.01 * $responsiveheadings;
    }
  }
}

@mixin h6 {
  @include headingstyle;
  font-size: rem($h6-size);
  line-height: rem($h6-size) + rem($headingincrement);
  @if $responsiveheadings
  {
    @include snap-down-from('md')
    {
      font-size: rem($h6-size * 0.01 * $responsiveheadings);
      line-height: rem($h6-size) + rem($headingincrement) * 0.01 * $responsiveheadings;
    }
  }
}

h1, .h1, button.no-decoration.h1
{
  @include h1;
  @include additionalheadingstyle;
}

h2, .h2, button.no-decoration.h2
{
  @include h2;
  @include additionalheadingstyle;
}

h3, .h3, button.no-decoration.h3
{
  @include h3;
  @include additionalheadingstyle;
}

h4, .h4, button.no-decoration.h4
{
  @include h4;
  @include additionalheadingstyle;
}

h5, .h5, button.no-decoration.h5
{
  @include h5;
  @include additionalheadingstyle;
}

h6, .h6, button.no-decoration.h6
{
  @include h6;
  @include additionalheadingstyle;
}

section, .section
{
  margin-top: $extra-large-spacing;
  margin-bottom: $extra-large-spacing;  
}

strong.impact
{
  @include impactful;
}

p, strong
{
  &:not(:last-child) {
    margin-bottom: $small-spacing;
  }
}

sup, p.small, strong.small, span.small, li.small
{
  @extend .rwxt-small;
}

a, .rwx-link
{
  cursor: pointer;
  &:not(.rwx-button):not(.no-decoration)
  {
    text-decoration:none;
    color:$font-link-color;
    display:inline-block;
    position:relative;
    &:after
    {
      display:inline-block;
      content:"";
      width:0px;
      height:1px;
      background-color:$font-link-color;
      transition: all 0.3s $transition-fast-in;
      position:absolute;
      bottom:0px;
      width:100%;
      left:0;
    }
    &:focus
    {
      @include focus-ring;
    }
    &:hover, &.active
    {
      &:after
      {
        transition: all 0.3s $transition-fast-in;
        left:45%;
        width:5%;
      }
    }
    &.\--inverted
    {
      &:after
      {
        width:0;
        left:50%;
      }
      &:hover, &.active
      {
        &:after
        {
          left:0;
          width:100%;
        }
      } 
    }
  }
}

$ul-size: 8px;
ul
{
  &:not(.no-decoration)
  {
    margin-bottom: $small-spacing;
    padding-left: $extra-small-spacing;
    li
    {
      position:relative;
      padding-left: $ul-size + $extra-small-spacing;
      &:not(:last-child) {
        margin-bottom:$extra-small-spacing;
      }
      ul
      {
        margin-top:$small-spacing;
      }
      &:before {
        position:absolute;
        left:0px;
        top:12px;
        content:"";
      }
    }
    &:not(.\--numbered)
    {
      li:before {
        width:$ul-size;
        height:2px;
        background: $list-item-counter-color;
      }
    }
    &.\--dotted
    {
      li:before
      {
        border-radius:100%;
        width:$ul-size;
        height:$ul-size;
        top:9px;
      }
    }
    // add rule for last P no margin
    &.\--numbered
    {
      counter-reset: ulnumbered;
      li {
        padding-left: $h6-size + $extra-small-spacing;
      }
      li:before {
        counter-increment: ulnumbered;
        content: counter(ulnumbered)".";
        color: $list-item-counter-color;
        @include h6;
        top:0px;
      }
    }
    &.\--inline
    {
      display:flex;
      flex-wrap:wrap;
      li
      {
        margin-right: $extra-large-spacing;
        &:before
        {
          height:$ul-size !important;
          width:$ul-size !important;
          top:9px;
          background: $list-item-counter-color;
          border-radius:100%;
        }
      }
    }  
  }
}

$weights: ("light": 200, "normal": 400, "semi-bold":500, "bold": 700, "extra-bold": 900);

@each $selector, $weight in $weights
{
	.rwxt-#{$selector}, .h1.rwxt-#{$selector}, .h2.rwxt-#{$selector}, .h3.rwxt-#{$selector}, .h4.rwxt-#{$selector}, .h5.rwxt-#{$selector}, .h6.rwxt-#{$selector}
	{
		font-weight: $weight !important;
	}
}

@each $selector,$measurements in $fontsizes
{
  .rwxt-#{$selector}
  {
    font-size: rem(map-get($measurements, "size")) !important;
    line-height: rem(map-get($measurements, "lh")) !important;
  }
}

.rwxt-base
{
  font-family:$font-base !important;
}

.rwxt-no-underline
{
  text-decoration: none !important;
}

.rwxt-underline
{
  text-decoration: underline !important;
}

.rwxt-uppercase
{
  text-transform: uppercase !important;
}

.rwxt-text-center
{
  text-align:center !important;
}

.rwxt-text-right
{
  text-align:right !important;
}

.rwxt-text-left
{
  text-align:left !important;
}

$cards: ( 
  "help": ("color": $orange, "icon": 'help-fill', "sw":"1px"),
  "feature": ("color": $light-green, "icon": 'star', "sw":"33px"),
  "note": ("color": $light-blue, "icon": 'note-fill', "sw":"1px"),
  "warning": ("color": $red, "icon": 'warning', "sw":"20px", "fill": "true"),
  "hint": ("color": $yellow, "icon": 'hint', "sw":"25px"),
);

p.dismissable
{
  padding: $small-spacing 70px $small-spacing 70px !important;
  .dismissable-button
  {
    position: absolute;
    right:0px;
    top:0px;
    height:100%;
    width:0px;
    background:rgba($white, 0.7);
    transition: all 0.5s $transition-slow-in;
    @include onAccessible(false)
    {
      width:60px;
      .card-close
      {
        opacity: 1;
      }
    }
    .card-close
    {
      transition: all 0.2s $transition-base;
      opacity: 0;
      width:20px;
      height:20px;
      @include absolute-center;
      background: icon('cross', $black, '50px');
    }
  }
  &:hover
  {
    outline:none;
    .dismissable-button
    {
      transition: all 0.5s $transition-slow-in;
      width:60px;
      .card-close
      {
        transition: all 0.5s $transition-fast-in;
        opacity:1;
      }
    }
  }
  &.dismiss
  {
    transition: all 0.5s $transition-slow-in;
    opacity:0;
    height: 0px !important;
    min-height: 0px !important;
    margin: 0px !important;
    padding: 0px !important;
  }
}

@each $card, $deets in $cards
{
  p.#{$card}
  {
    text-align:left;
    padding: $small-spacing $small-spacing $small-spacing 70px;
    position:relative;
    background-color: $card-background-color;
    box-shadow: 1px 1px 2px $card-border-color;
    min-height:80px;
    border-radius:8px;
    overflow:hidden;
    transform: skewX(-5deg);
    &:before
    {
      content: "";
      width:33px;
      height:33px;
      position:absolute;
      top:20px;
      left:10px;
      background: icon(#{map-get($deets,"icon")}, $white, map-get($deets,"sw"), map-get($deets,"fill")); 
      z-index:2;    
    }
    &:after
    {
      content: "";
      position:absolute;
      left:0px;
      top:0px;
      height:100%;
      width:50px;
      z-index:1;
      background-color: rgba(map-get($deets,"color"), 0.8);
    }
  }
}