$navwidth: 300px;

.navigation
{
	position: fixed;
	top:0px;
	left:0px;
	width:$navwidth;
	z-index:8;
	height:100%;
	.rwx-slider
	{
		height:100%;
	}
	ul
	{
		padding-top:120px;
		width:$navwidth;
		height:100%;
		li
		{
			flex:1;
			display:flex;
			button
			{
				margin-bottom:auto !important;
			}
			.rwx-animated-border
			{
				@include change-animated-border($orange, $blue);
			}
			button, a
			{
				display: block;
				width:60%;
				margin:auto;
				color:$white;
				text-decoration: none;
				&.active
				{
					svg
					{
						clip-path:circle(66%);
					}
					svg rect
					{
						fill: $blue;
						stroke: $orange;
					}
				}
				svg
				{
					z-index:1;
				}
				span
				{
					position: relative;
					z-index:2;
				}
			}
		}
	}

	&-icon
	{
		position:fixed;
		top:0;
		left:0;
		width:100px;
		height:100px;
		z-index:13;
		&-home, &-search
		{
			transition: 0.5s all $transition-base;
			outline: none;
			@include onAccessible(false)
			{
				transition: 0.5s all $transition-base;
				@include focus-ring;
			}
			&:hover
			{
				transform: scale(1.1);
			}
		}
		&-home
		{
			left:130px;
		}
		&-search
		{
			left:220px;
		}
	}

	&-control
	{
		border-bottom-right-radius: 100%;
		box-shadow: 1px 1px 3px $orange;
		transition: 0.5s all $transition-base;
		@include onAccessible(true)
		{
			@include focus-ring;
			width:120px;
			height:120px;
			transition: 0.5s all $transition-base;
		}
		span
		{
			position:absolute;
			left:15px;
			top:20px;
			width:50px;
			height:4px;
			border-radius:4px;
			z-index:10;
			&:first-child, &:nth-child(3)
			{
				transform-origin:right;
			}
			&:nth-child(2)
			{
				margin-top:18px;
			}
			&:nth-child(3)
			{
				margin-top:36px;
			}
		}
	}
}