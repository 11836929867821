.search
{
  display: flex;
  flex-direction:column;
  position: fixed;
  width:100vw;
  top:0px;
  left:0px;
  height: 100%;
  z-index:10;
  padding-top:120px;
  @include snap-up-from('lg')
  {
    padding-top:0px;
    margin-left: $navwidth;
    width: calc(100vw - #{$navwidth});
  }
  &-results
  {
    height:100%;
    overflow: auto;
  }
  &-result
  {
    text-align:left;
    box-shadow: 0px 0px 5px 3px silver;
    display: block;
    width:100%;
    transition: all 0.5s $transition-base;
    @include onAccessible(true)
    {
      transition: all 0.5s $transition-base;
      box-shadow: 0px 0px 15px 8px silver;
    }
    &.resource
    {
      background: rgba($resource-color, 0.1);
    }
    &.method
    {
      background: rgba($methods-color, 0.1);
    }
    &.configuration
    {
      background: rgba($configuration-color, 0.1);
    }
  }
  input[type="search"]
  {
    position: relative;
    border:none;
    outline:none;
    font-weight: 200 !important;
    background-repeat: no-repeat;
    background-image: icon('search', $silver, 30px);
    background-size: 0px;
    background-position: -32px 14px;
    transition: all 0.5s $transition-slow-in;
    @include onAccessible(false)
    {
      padding-left: 44px !important;
      background-position: 0px 14px;
      transition: all 0.5s $transition-slow-in;
      background-size: 32px;
    }
    &::placeholder
    {
      color:$silver;
    }
  }
}