@import '../core/rwx-variables';
@import '../core/rwx-mixins';
@import '../core/rwx-functions';

$button-size: 32px;

.rwx-slideshow
{
  .slide-container
  {
    height:100%;
    width:100%;
  }

  &:focus
  {
    @include focus-ring;
  }

  &-background
  {
    @include full-size;
    transition: background-position 1s ease;
    z-index:-1;
    opacity:0.8;
  }

  .slide
  {
    height:100%;
    perspective:1000px;
    float:left;
    transition: all 1s ease;
    padding-left: $button-size + $medium-spacing + $small-spacing + $medium-spacing;
    padding-right: $button-size + $medium-spacing + $small-spacing + $medium-spacing;
    @include flex-center;
    flex-direction:column;
    &-title, &-content
    {
      min-width:300px;
      max-width: 800px;
      transform-style: preserve-3d;
    }
    &-title
    {
      color:white;
      text-align:center;
    }
    &-content
    {
      position:relative;
      background: rgba(255,255,255,0.5);
    }
    &-container
    {
      perspective:500px;
    }
  }

  .next-slide, .prev-slide
  {
    z-index:$top-layer-z;
    position:absolute;
    cursor:pointer;
    background:rgba(255,255,255,0.5);
    transition: box-shadow 0.5s $transition-base;
    @include onAccessible(true)
    {
    	transition: box-shadow 0.5s $transition-base;
			box-shadow: 0 0 1rem 0.1rem $black;
      outline: none;
    }
  }

  .next-slide, .prev-slide
  {
    top:50%;
    transform:translateY(-50%);
    display:inline-block;
  }

  .next-slide, .prev-slide {
    &:before {
      content: "";
      width:$button-size;
      height:$button-size;
      display: block;      
    }
  }

  .next-slide
  {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding:$medium-spacing $small-spacing $medium-spacing $medium-spacing;
    right:0px;
		&:before {
			background: icon('chev', $black, '30px');
		}
  }
  .prev-slide
  {
  	padding:$medium-spacing $medium-spacing $medium-spacing $small-spacing;
    left:0px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
		&:before {
			background: icon('chev', $black, '30px');
      transform: rotate(-180deg);
		}
  }  
}

