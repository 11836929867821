.interactive-demo
{
	position: relative;
	height:100%;
}

.live-function, .live-interaction
{
	cursor:pointer;
	position: absolute;
	width: $large-spacing*2;
	height: $large-spacing*2;
	z-index:2;
	transition: 0.5s all $transition-base;
	z-index:10;
	background: $resource-color !important;
	@each $selector, $color in $colors
	{
		&.\--#{$selector}
		{
			box-shadow: -1px 1px 3px $color;
		}
	}
	i
	{
		margin-right:-8px;
		margin-top:-4px;
	}
	&:focus, &:hover
	{
		width: $large-spacing*3;
		height: $large-spacing*3;
		transition: 0.5s all $transition-base;
	}
}

.live-function
{
	bottom:0;
	left:0;
	border-top-right-radius: $large-spacing*2;
	i
	{
		margin-left:-16px;
		margin-top:14px;
	}
	&:focus, &:hover
	{
		@include focus-ring;
		border-top-right-radius: $large-spacing*3;
	}
}

.live-interaction
{
	top:0;
	right:0;
	border-bottom-left-radius: $large-spacing*2;
	i
	{
		margin-right:-8px;
		margin-top:-4px;
	}
	&:focus, &:hover
	{
		@include focus-ring;
		border-bottom-left-radius: $large-spacing*3;
	}
}

.configuration-options, .methods
{
	position:absolute;
	box-shadow: -3px 3px 13px 0px $white;
	max-height:100%;
	overflow: auto;
	z-index:11;
	background-color: $resource-color;
	@include snap-down-from('sm')
	{
		width:100%;
	}
	&.rwxc-color-white .rwx-form-item
	{
		@include change-input-color($white, $white);
	}
}

.methods
{
	transform-origin: bottom left;
	bottom:0;
	left:0;	
	max-width:100%;
	.overflower > button:not(:first-child)
	{
		margin-left:16px;
	}
	.overflower
	{
		white-space: nowrap;
		overflow: auto;
		padding:4px;
	}
	.interactable-method
	{
		display:inline-flex;
		flex-direction:column;
	}
}

.configuration-options
{
	width:50%;
	@include snap-down-from('md')
	{
		width:100%;
	}
	transform-origin: top right;
	top:0;
	right:0;
	.rwx-form-item
	{
		padding-left:4px;
		padding-right:4px;
	}
	.restrict-height
	{
		max-height:625px;
		overflow:auto;
		padding-top:5px;
		padding-bottom:5px;
	}
	.color-square
	{
		width:20px;
		height:20px;
		border: 2px solid $black;
		@include onAccessible(true)
		{
			@include focus-ring;
		}
		&.big
		{
			width:$form-item-size;
			height: $form-item-size;
		}
		&.active
		{
			box-shadow: 0 0 15px 3px $black;
		}
		&-popout
		{
			display: none;
			position: absolute;
			top:0px;
			left:0px;
			border-bottom-left-radius: 16px;
			border-bottom-right-radius: 16px;
			border-top-right-radius: 16px;
			&.show
			{
				display: inherit;
				z-index: 3;
			}
		}
		&-container
		{
			order:2;
			flex-basis:67%;
			position:relative;
		}
	}
}

.demo-box.small .configuration-options .restrict-height
{
	max-height:232px;
}