@import url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import '../../icons/rwx-iconset';

//color palette
$black: #000000 !default;
$grey: #E3E3E3 !default;
$dark-grey: #313131 !default;
$silver: #263547 !default;
$white: #FFFFFF !default;
$orange: #E65C00 !default;
$red: #FF3333 !default;
$blue: #1342A0 !default;
$light-blue: #3399FF !default;
$turquoise: #39EEB2 !default;
$green: #1C7503 !default;
$light-green: #3EEE3E !default;
$yellow: #EDD400 !default;
$purple: #731773 !default;
$pink: #FF33CC !default;
$brown: #9A3300 !default;

$ecolors: ('black':$black, 
					'grey':$grey, 
					'dark-grey':$dark-grey, 
					'white':$white, 
					'orange':$orange,
					'red': $red,
					'blue': $blue,
					'light-blue': $light-blue,
					'green': $green,
					'light-green': $light-green,
					'turquoise': $turquoise,
					'yellow': $yellow,
					'purple': $purple,
					'pink': $pink,
					'brown': $brown,
					'silver': $silver
					);
$colors: null !default;
@if $colors
{
	$colors: map-merge($ecolors, $colors);
}
@else
{
	$colors: $ecolors;
}

//theme
$theme-color-base: $dark-grey !default;
$theme-color-secondary: $grey !default;
$theme-color-tertiary: $light-blue !default;


//icons
$allow-icons: true !default;
$allow-colored-icons: true !default;
$allow-sized-icons: true !default;
$icon-color-base: $theme-color-base;
$icon-line-width: 10px;

//grid
$grid-item-gutter:30px !default;
$grid-item-gutter-vertical: 30px !default;
$breakpoint-mini: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;


//breakpoints
$breakpoints: (
	mini: $breakpoint-mini,
	sm: $breakpoint-sm,
	md: $breakpoint-md,
	lg: $breakpoint-lg,
	xl: $breakpoint-xl
) !default;


//spacings
$extra-small-spacing: 8px !default;
$small-spacing: 16px !default;
$medium-spacing: 24px !default;
$large-spacing: 32px !default;
$extra-large-spacing: 40px !default;
$espacings: ("no": 0, "sm": $small-spacing, "md": $medium-spacing, "lg": $large-spacing, "xs": $extra-small-spacing, "xl": $extra-large-spacing);
$spacings: null !default;
@if $spacings
{
	$spacings: map-merge($espacings, $spacings);
}
@else
{
	$spacings: $espacings;
}

//type
$responsiveheadings: 75 !default;
$font-size-small: 12px !default;
$font-size-base: 16px !default;
$font-size-large: 20px !default;
$line-height-small: 20px !default;
$line-height-base: 24px !default;
$line-height-large: 28px !default;
$h1-size: $font-size-base * 3.3 !default;
$h2-size: $font-size-base * 2.7 !default;
$h3-size: $font-size-base * 2.2 !default;
$h4-size: $font-size-base * 1.8 !default;
$h5-size: $font-size-base * 1.5 !default;
$h6-size: $font-size-base * 1.3 !default;
$underline-headings: false !default;
$font-base: 'Roboto Mono', monospace !default;
$font-heading: 'Josefin Sans', sans-serif !default;
$font-color-base: $black !default;
$font-link-color: $theme-color-tertiary !default;
$list-item-counter-color: $theme-color-tertiary !default;
$card-background-color: rgba($theme-color-secondary,0.4) !default;
$card-border-color: $theme-color-base !default;
$efontsizes: (
	"small": ("size": $font-size-small, "lh": $line-height-small),
	"large": ("size": $font-size-large, "lh": $line-height-large)
);
$fontsizes: null !default;
@if $fontsizes
{
	$fontsizes: map-merge($efontsizes, $fontsizes); 
}
@else
{
	$fontsizes: $efontsizes;
}

//tables
$table-color-base: $theme-color-base !default;
$table-color-secondary: $theme-color-secondary !default;
$table-color-highlight: $theme-color-tertiary !default;
$table-height-limit: 500px !default;
$table-cell-width-limit: 200px !default;

//forms
$form-color-base: $theme-color-base !default;
$form-color-secondary: $theme-color-secondary !default;
$form-color-valid: $black !default;
$form-color-invalid: $red !default;
$form-item-size: 50px !default;
$form-inline-input-size: 67% !default;

//focus-ring
$focus-ring-color: $turquoise !default;
$focus-ring-shadow: 0 0 0.3rem 0.1rem $focus-ring-color !default;

//buttons
$button-size: 50px !default;
$button-padding: 20px !default;
$button-color-base: $theme-color-base !default;
$button-color-white: $black !default;
$button-font-size: 18px !default;

//Slider
$slider-counter-active-color: $theme-color-base !default;
$slider-counter-inactive-color: $theme-color-secondary !default;
$slider-counter-size: 20px !default;

//Tabs
$tabs-title-color: $theme-color-base !default;
$tabs-title-size: $h5-size !default;
$tabs-active-color: $theme-color-tertiary !default;
$tabs-seperator-color: $theme-color-secondary !default;

// Z's
$top-layer-z: 9999;

//transitions
$transition-slow-in: cubic-bezier(.09,1.08,.74,.95); 
$transition-slow-in-slow-out: cubic-bezier(0,.44,1,.45);
$transition-fast-in: cubic-bezier(1,.15,.72,.81); 
$transition-base: cubic-bezier(.11,.82,.63,.94);
$transition-out-back: cubic-bezier(0.34, 0.37, 0.47, 1.63);
$transition-out-back-fast: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$transition-out-back-slow: cubic-bezier(0, 1.4, 1, 1);
$transition-zip: cubic-bezier(0,1.01,.19,1);
$transition-smooth: cubic-bezier(.44,.82,.47,.98);
$transitions: (
	"slow-in": $transition-slow-in,
	"slow-in-out": $transition-slow-in-slow-out,
	"fast-in": $transition-fast-in,
	"base": $transition-base,
	"out-back": $transition-out-back,
	"out-back-fast": $transition-out-back-fast,
	"out-back-slow": $transition-out-back-slow,
	"zip": $transition-zip,
	"smooth": $transition-smooth
);
