@mixin slideCopy {
	transition: 0.5s all $transition-slow-in;
	top:0px;
	right:0px;
	&.left
	{
		left:0px;
	}
}

.code-snippet
{
	position: relative;
	&.clicked
	{
		animation: bounce 0.3s ease-in-out;
	}
	pre
	{
		cursor: pointer;
	}
	&:hover
	{
		.code-snippet-copy
		{
			@include slideCopy;
		}
	}
	&-copy
	{
		@include onAccessible(false)
		{
			@include slideCopy;
		}
		&-container
		{
			overflow:hidden;
			width:52px;
			height:52px;		
			top:0px;
			right:0px;
			&.left
			{
				left:0px;
			}
		}
		&-container, &
		{
			position: absolute;
		}
		transition: 0.5s all $transition-slow-in;
		border-bottom-left-radius: 48px;
		box-shadow: 1px 1px 3px $white;
		top:-48px;
		right:-48px;
		width:48px;
		height:48px;
		i
		{
			margin-right:-8px;
			margin-top:-4px;
			width: 1.8rem;
			height:1.8rem;
		}
		&.left
		{
			border-bottom-right-radius: 48px;
			border-bottom-left-radius: 0px;
			left: -48px;
			i
			{
				margin-left:-10px;
			}
		}
	}
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  50% {
  	transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}