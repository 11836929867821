section
{
  position: relative;
  &.methods-section .copy-link
  {
    background-color: $methods-color;
  }
  &.configuration-section .copy-link
  {
    background-color: $configuration-color;
  }
  &.notes-section .copy-link
  {
    background-color: $notes-color;
  }
  &.instructions-section .copy-link
  {
    background-color: $instructions-color;
  }
  .copy-link
  {
    overflow: hidden;
    cursor:pointer;
    position: absolute;
    right:0px;
    top:0px;
    height: 100%;
  }
}