$spacingtypes: ("margin": "m", "padding": "p");
$directions: ("left": "l", "right": "r", "top" :"t", "bottom": "b");

@each $size, $unit in $spacings {
	@each $property, $selector in $spacingtypes
	{
		.rwxs-#{$selector}
		{
			&-#{$size}
			{
				#{$property}: $unit !important;
			}
			
			@each $direction, $dselector in $directions
			{
				&-#{$dselector}-#{$size}
				{
					#{$property}-#{$direction}: $unit !important;
				}
			}
		}
	}	
}

//ensure rsp classes precede in hierarchy
@each $size, $unit in $spacings {
	@each $property, $selector in $spacingtypes
	{
		.rwxs-#{$selector}
		{
			&-#{$size}-rsp-lg
			{
				@include snap-down-from('lg')
				{
					#{$property}: $unit !important;
				}
			}
			@each $direction, $dselector in $directions
			{
				&-#{$dselector}-#{$size}-rsp-lg
				{
					@include snap-down-from('lg')
					{
						#{$property}-#{$direction}: $unit !important;
					}
				}
			}
		}
	}	
}

@each $size, $unit in $spacings {
	@each $property, $selector in $spacingtypes
	{
		.rwxs-#{$selector}
		{
			&-#{$size}-rsp
			{
				@include snap-down-from('md')
				{
					#{$property}: $unit !important;
				}
			}
			@each $direction, $dselector in $directions
			{
				&-#{$dselector}-#{$size}-rsp
				{
					@include snap-down-from('md')
					{
						#{$property}-#{$direction}: $unit !important;
					}
				}
			}
		}
	}	
}

@each $size, $unit in $spacings {
	@each $property, $selector in $spacingtypes
	{
		.rwxs-#{$selector}
		{
			&-#{$size}-rsp-sm
			{
				@include snap-down-from('sm')
				{
					#{$property}: $unit !important;
				}
			}
			@each $direction, $dselector in $directions
			{
				&-#{$dselector}-#{$size}-rsp-sm
				{
					@include snap-down-from('sm')
					{
						#{$property}-#{$direction}: $unit !important;
					}
				}
			}
		}
	}	
}