@import '../core/rwx-variables';
$indent: 15px;

.rwx-number-switcher {
	display:inline-flex;
	align-items:center;
	border-radius: $indent;
	padding:$small-spacing;
	font-weight:bold;
	.value
	{
		overflow-x:auto;
		overflow-y:hidden;
	}
	i, img, span:not(.value)
	{
		margin-right:$indent;
	}
  &.\--right
  {
  	i, img, span:not(.value)
  	{
  		margin-right:0px;
  		margin-left:$indent;
  		order:1;
  	}
  	.value
  	{
  		order:0;
  	}
  }

  i, img
  {
    width:40px;
    height:40px;
  }

	span:not(.value)
	{
		transform:rotate(-30deg);
		padding:$extra-small-spacing;
		text-align:center;
	}
	&.pulsate
	{
		animation: pulsate 1s ease-in-out;
	}
}

@keyframes pulsate{
	0% {
		transform:scale(1);
	}	
	50% {
		transform:scale(1.3);
	}
	100% {
		transform: scale(1);
	}
}