.page-animation-wrapper
{
	position:absolute;
	width:100%;
	min-height:100vh;
	left:0px;
	top:0px;
}

.page
{
	padding-bottom:100px;
}

.page-content
{
	overflow: hidden;
}

body
{
	overflow-x:hidden;
}

#brackets
{
	background: rgb(65,9,9);
	background: linear-gradient(21deg, rgba(65,9,9,1) 0%, rgba(6,5,38,1) 33%, rgba(6,5,38,1) 66%, rgba(65,9,9,1) 100%);
}