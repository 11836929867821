@import '../core/rwx-variables';
@import '../core/rwx-mixins';

$rwx-option-selectors-colors: (
	$orange,
	$light-blue,
	$light-green,
	$yellow,
	$pink,
	$purple
) !default;

$opacity: 0.7;
$animation-easing: cubic-bezier(.01,.89,.53,.98);
$top-left: translate(-101%, -101%);
$top-right: translate(101%, -101%);
$bottom-left: translate(-101%, 101%);
$bottom-right: translate(101%, 101%);
$left: translate(-101%, 0%);
$right: translate(101%, 0%);
$top: translate(0%, -101%);
$bottom: translate(0%, 101%);

$osdirections: (
	'optionSelectorSlideOutTopLeft': $top-left,
	'optionSelectorSlideOutTopRight': $top-right,
	'optionSelectorSlideOutBottomLeft': $bottom-left,
	'optionSelectorSlideOutBottomRight': $bottom-right,
	'optionSelectorSlideOutLeft': $left,
	'optionSelectorSlideOutRight': $right,
	'optionSelectorSlideOutBottom': $bottom,
	'optionSelectorSlideOutTop': $top
);

$numberofitems: (
	'one': (
		'optionSelectorSlideOutTop'
	),
	'two': (
		'optionSelectorSlideOutLeft',
		'optionSelectorSlideOutRight'
	),
	'three': (
		'optionSelectorSlideOutLeft',
		'optionSelectorSlideOutRight',
		'optionSelectorSlideOutBottom'
	),
	'four': (
		'optionSelectorSlideOutTopLeft',
		'optionSelectorSlideOutTopRight',
		'optionSelectorSlideOutBottomLeft',
		'optionSelectorSlideOutBottomRight'
	),
	'five': (
		'optionSelectorSlideOutTopLeft',
		'optionSelectorSlideOutTopRight',
		'optionSelectorSlideOutLeft',
		'optionSelectorSlideOutRight',
		'optionSelectorSlideOutBottom'	
	),
	'six': (
		'optionSelectorSlideOutTopLeft',
		'optionSelectorSlideOutTop',
		'optionSelectorSlideOutTopRight',
		'optionSelectorSlideOutBottomLeft',
		'optionSelectorSlideOutBottom',
		'optionSelectorSlideOutBottomRight'
	)
);

.rwx-option-selector
{
	display:flex;
	flex-wrap:wrap;
	& > &-item > *
	{
		cursor: pointer;
		@include change-animated-border($black, $white);
	}
	& > &-item
	{
		padding-left: $large-spacing;
		padding-right: $large-spacing;
		z-index:9;
		flex: 1 1 50%;
		@include flex-center;
		&.active
		{
			animation: optionSelectorFinal 1s $animation-easing forwards;
		}

		@for $i from 1 through 6 {
			&:nth-child(#{$i})
			{
				background: rgba(nth($rwx-option-selectors-colors, $i), $opacity);
			}
		}
	}

	@each $item, $val in $numberofitems {
		&.#{$item}
		{
			$submap: map-get($numberofitems,$item);
			@each $child in $submap
			{
				$i: index($submap, $child);
				> .rwx-option-selector-item:nth-child(#{$i})
				{
					transform: map-get($osdirections, $child);
					&.remove
					{
						animation: #{$child} 1s $animation-easing forwards;
					}
				}				
			}
		}
	}

	&.three
	{
		> .rwx-option-selector-item:nth-child(3)
		{
			flex: 1 1 100%;
		}	
	}

	&.five
	{
		> .rwx-option-selector-item:nth-child(5)
		{
			flex: 1 1 100%;
		}
	}
	&.six
	{
		> .rwx-option-selector-item
		{
			flex: 1 1 33%
		}
	}
}

@keyframes optionSelectorFinal {
	100% {
		transform: translate(0%, 0%);
	}
}

@each $dir,$val in $osdirections
{
	@keyframes #{$dir} {
		0% {
			transform: translate(0%, 0%);
		}
		100% {
			transform: $val;
		}	
	}
}