.hub-link
{
  position: relative;
  overflow: hidden;
  height:100%;
  .underline
  {
    height:5px;
    left:50%;
    position: absolute;
    bottom:0px;
  }
  .ident
  {
    position: absolute;
    top:0px;
    right:0px;
    width: 0;
    height: 0;
    border-style: solid;
    z-index:-1;
  }
  @include snap-down-from('md')
  {
    .rwx-split:nth-of-type(2)
    {
      max-width:50%;
    }
  }
}