.back-to-parent
{
  position: relative;
  display: block;
  height:80px;
  width:80px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  transition: 0.5s all $transition-base;
  
  @include onAccessible(true)
  {
    @include focus-ring;
    width: 100%;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    transition: 0.5s all $transition-base;
  }
}