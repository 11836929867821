@import '../core/rwx-variables';
@import '../core/rwx-mixins';

$rwx-slide-ticker-background-color: $orange !default;

.rwx-slide-ticker
{
	@include full-size;
	@include flex-center;
	opacity: 0;
	z-index:-1;
	overflow:hidden;
	background: rgba($rwx-slide-ticker-background-color, 0.7);
	cursor:pointer;
	& > &-item
	{
		font-size: 6rem;
		@include snap-down-from('md')
		{
			font-size: 3rem;
		}
		&.go
		{
			span
			{
  			display: inline-block;
  			animation: wiggle 0.5s ease-in-out infinite;
			}
		}
	}
}

@keyframes wiggle {
  33% {
    transform: rotate(-20deg) skew(10deg, 10deg);
  }
  66% {
    transform: rotate(20deg) skew(-10deg, -10deg);
  }
  100% {
    transform: rotate(0deg) skew(0deg, 0deg);
  }
}