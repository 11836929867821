.option-list
{
  position: absolute;
  box-shadow: 1px 1px 3px $light-blue;
  transform-origin: top left;
  z-index:5;
  &:after
  {
    position: absolute;
    top:0px;
    left:0px;
    content:"";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8px 0 0;
    border-color: $light-blue transparent transparent transparent;
  }
  ul
  {
    @include change-list-color($white);
    max-height:400px;
    overflow: auto;
  }
  &-open
  {
    @include change-link-color($orange);
  }
}