.css-selector
{
  display: flex;
  flex-direction: column;
  align-items:center;
  .child
  {
    transform: rotate(90deg);
    margin-right:-8px;
    font-size: 48px;
  }
}