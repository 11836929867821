.step
{
	min-height:240px;
	position: relative;
	&-icon
	{
		position: absolute;
		top:0px;
		opacity: 0.8;
	}
	&.right
	{
		flex-direction:row-reverse;
		.step-icon
		{
			right:0px;
			border-bottom-left-radius: 100%;
		}
	}
	&.left
	{
		.step-icon
		{
			left:0px;
			border-bottom-right-radius: 100%;
		}
	}

	&-arrow
	{
		svg, &
		{
			min-height:200px;
			max-height:200px;
		}
		svg
		{
			fill:none;
			stroke-width:3px;
		}

		&.rwxsx-end
		{
			svg path.main-branch
			{
				stroke: $silver;
				stroke-dashoffset: 0;
				transition: 1s all $transition-slow-in;
			}

			// svg path.branch
			// {
		 //    &:nth-of-type(odd){
   //      	stroke: $red;
   //      }
		 //    &:nth-of-type(even){
   //      	stroke: $blue;
   //      }
			// 	opacity:0.5;
			// 	@for $i from 1 through 10 {
			// 	  &:nth-child(#{$i})
			// 	  {
			// 	  	stroke-dashoffset: 0;
			// 	  	transition: 1s all $transition-slow-in #{$i*0.1}s;
			// 	  }
			// 	}
			// }
		}
	}

	&-content
	{
		height:100%;
	}

	&-shadow, .code-snippet pre
	{
		box-shadow: 0px 0px 10px 1px $white;
	}

	&-shadow
	{
		background: linear-gradient(to bottom, #2a2139 75%, #34294f);
	}
}