.popout
{
	z-index: 999;
	position:fixed;
	width:100vw;
	height:100vh;
	top:0px;
	left:0px;
	@include flex-center;
	&-content
	{
		box-shadow: 2px 2px 1px 1px $blue;
		border-radius:6px;
	}
}