$normal-line : 1px solid $table-color-secondary;
$emphasis-line:	2px solid $table-color-base;

@mixin stuck {
	@extend .rwxt-bold;
	left:0px;
	position:absolute;
	background-color:$white !important;
	box-shadow: 0px 3px 5px $table-color-secondary;	
	cursor: default !important;
	&:hover span
	{
		cursor: default !important;
		background-color:$white !important;
	}
}

// normal - .rwx-table .rwx-table-data:first-child
// vertical - .rwx-table.vertical .rwx-table-data span span:first-child
.rwx-table
{
	max-height:$table-height-limit;
	overflow:auto;
	position:relative;
		
	.scroll-mask
	{
		&-vertical, &
		{
			@include stuck;
			z-index:4;
			box-shadow: none;
			border-top: $emphasis-line;
			border-left: $emphasis-line;
		}
		&-vertical
		{
			border-top:none;
			border-left: none;
			border-bottom: $emphasis-line;
			border-right: $emphasis-line;
		}
	}
	&-data
	{
		display:flex;
		>span
		{
			word-break:break-word;
			margin-bottom:0px;
			padding: $small-spacing;
			flex:1;
			&.\--x2
			{
				flex:2;
			}
			&.\--x3
			{
				flex:3;
			}
			&.scroll
			{
				animation: stickyHeaderHorizontal 0.6s $transition-base;
				@include stuck;
				z-index:3;	
			}
		}

		&.scroll
		{
			animation: stickyHeaderVertical 0.6s $transition-base;
			@include stuck;
			z-index:2;
			.scroll
			{
				display:none;
			}
		}
	}

	&.\--vertical-line &-data >span
	{
		border-left: $normal-line;
	}

	&:not(.\--vertical-line) &-data >span
	{
		border-top: $normal-line;
	}

	&.\--vertical-line.\--vertical &-data >span:first-child, &:not(.\--vertical).\--vertical-line &-data:first-child >span, &.\--vertical-line.\--vertical &-data >span.scroll, &:not(.\--vertical).\--vertical-line &-data.scroll >span
	{
		border-top: none;
		border-left: $emphasis-line;
	}

	&:not(.\--vertical-line) &-data:first-child >span, &:not(.\--vertical-line) &-data.scroll >span
	{
		border-top: $emphasis-line;
	}

	&.\--vertical &-data >span:first-child, &:not(.\--vertical) &-data:first-child >span, &.\--dual-headings &-data >span:first-child, &.\--dual-headings &-data:first-child >span
	{
		@extend .rwxt-bold;
	}

	&.\--dual-headings &-data >span:first-child, &.\--dual-headings &-data >span.scroll
	{
		border-left: $emphasis-line;
	}

	&.\--dual-headings.\--vertical-line &-data:first-child >span:first-child, &.\--dual-headings.\--vertical-line &-data.scroll >span:first-child
	{
		border:none;
	}

	&.\--slanted:not(.\--vertical) &-data:first-child >span, &.\--slanted.\--vertical &-data >span:first-child
	{
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content:center;
		max-height: 150px;
		word-break: break-word;
		border:none;
		writing-mode: vertical-rl;
		&:before
		{
			content:"";
			height:100%;
			width:2px;
			background-color:$table-color-base;
		}
	}

	&.\--slanted:not(.\--vertical) &-data:first-child >span
	{
		transform: rotate(-133deg) translate(25px, -25px);
	}

	&.\--slanted.\--vertical &-data >span:first-child
	{
		transform: rotate(-113deg) translate(12px, 12px)
	}

	&.\--slanted.\--vertical
	{
		padding-bottom:70px;
	}

	&.\--full-grid &-data
	{
		>span
		{
			border-top: $normal-line !important;
			border-left: $normal-line !important;
			&:last-child
			{
				border-right: $normal-line;
			}
		}
		&:last-child >span
		{
			border-bottom: $normal-line;
		}
	}

	&.\--highlightable.\--vertical &-data
	{
		&:hover >span:not(:first-child)
		{
			cursor:pointer;
			background-color: rgba($table-color-highlight, 0.2);			
		}
	}


	&.\--highlightable:not(.\--vertical) &-data:not(:first-child)
	{
		&:hover >span
		{
			cursor:pointer;
			background-color: rgba($table-color-highlight, 0.2);
		}
	}

	&.\--colored &-data
	{
		&.scroll, &:nth-child(odd)
		{
			>span
			{
				background-color: rgba($table-color-highlight, 0.2);
			}
		}
	}

	@include snap-down-from('md')
	{
		&-data >span
		{
			word-break:break-word;
			min-width:$table-cell-width-limit;
			&.\--x2
			{
				min-width: $table-cell-width-limit * 2;
			}
			&.\--x3
			{
				min-width: $table-cell-width-limit * 3;
			}
		}
	}
}

@keyframes stickyHeaderHorizontal
{
	0%
	{
		transform: translateX(-100%);
		opacity: 0;
	}
	100%
	{
		transform: translateX(0);
		opacity:1;
	}
}

@keyframes stickyHeaderVertical
{
	0%
	{
		transform: translateY(-100%);
		opacity:0;
	}
	100%
	{
		transform: translateY(0);
		opacity:1;
	}
}