.spacings-legend
{
  position: absolute;
  top:0px;
  left:0px;
  .margin, .padding
  {
    &:before
    {
      margin-right:10px;
      content: "";
      width:10px;
      height:10px;
      display: inline-block;
    }
  }
  .margin:before
  {
    background: $silver;
  }
  .padding:before
  {
    background: $grey;
  }
}

.configuration .configuration-section:not(:last-child)
{
  padding-bottom: $extra-large-spacing;
}
