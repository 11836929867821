.demo-box
{
	position: relative;
	overflow:hidden;
	height: 80vh;
	box-shadow: 0px 0px 10px 1px inset $resource-color;
	&.small
	{
		height:40vh;
	}
	>div
	{
		height:100%;
		overflow:auto;
	}
}