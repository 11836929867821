.radius {
	border-radius:16px;
}

.dinline
{
	display: inline-block;
}

.rwx-tabs-container
{
	@include hideScrollbar;
}

.highlight-pulsate
{
	position: relative;
	&:before
	{
		z-index: -1;
		content: "";
		position: absolute;
		left: -24px;
		width: calc(100% + 48px);
		top: -24px;
		height: calc(100% + 48px) ;
		animation: 1s pulsate ease-in;
	}
}

#lava-lamp-home
{
	opacity:0.1;
	path
	{
		fill: $silver;
	}
}

@keyframes pulsate {
  0% {
    background: $white;
  }

  50% {
    background: #c9d1ff;
  }

  100% {
  	background: $white;
  }
}