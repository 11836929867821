@import '../core/rwx-variables';

$rwx-timeline-ring-color: $blue !default;
$rwx-timeline-line-color: $grey !default;
$rwx-timeline-fill-color: $grey !default;

@mixin blob
{
	width:32px;
	height:32px;
	border-radius:100%;
	position: absolute;
}

.rwx-timeline
{
	position: relative;
	padding-left: 44px;
	& > &-tracker
	{
		position: relative;
		padding-top:$small-spacing;
		padding-bottom:$small-spacing;
		padding-left:$small-spacing;
		opacity: 0;
		text-indent:$large-spacing;
		.rwx-timeline-tracker-blob
		{
			top:14px;
			left:-34px;
			@include blob;
		}
		&.activated
		{
			opacity:1;
			text-indent:0px;
			transition: all 0.8s $transition-base;
			.rwx-timeline-tracker-blob
			{
				animation: pulse 0.8s ease-in forwards;			
			}
		}
	}

	&:before
	{
		content: "";
		width:8px;
		height:100%;
		background:$rwx-timeline-line-color;
		position:absolute;
		left:20px;
		top:0px;
	}
	&-blob
	{
		@include blob;
		top:-14px;
		left:9px;
		background-color:$rwx-timeline-line-color;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity:0;
		border: 1px solid $rwx-timeline-ring-color;
	}
	50% {
		transform: scale(1.3);
		opacity: 0.5;
		border: 3px solid $rwx-timeline-ring-color;
	}
	100% {
		transform: scale(1);
		opacity:1;
		background: $rwx-timeline-fill-color;
		border: 6px solid $rwx-timeline-ring-color;
	}
}