.note
{
	&:not(p)
	{
		position: relative;
		min-height:240px;
		height:100%;
		display: flex;
		align-items:center;
		justify-content:flex-end;
		padding-right:40px;
		padding-left:140px;
		padding-top:24px;
		padding-bottom:24px;
		margin-left: 24px;
		margin-right:24px;
	}
	&-background
	{
		@include full-size;
		z-index:-1;
		// transform: skew(-10deg);
	}
	&-icon
	{
		position:absolute;
		bottom:13px;
		opacity:0.4;
		left:0px;
		i
		{
			width:100px;
			height:100px;
		}
	}
}